import { useState, useEffect, useRef } from 'react'
import './App.css';
import * as THREE from 'three';
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { SketchPicker } from 'react-color';
import { svgPath, bezierCommand } from './code'

const App = () => {

  const shapes = [{
    name: 'sphere',
    code: '<svg viewBox = "0 0 169.1 338.19"><path d="M0,.5C93.11,.5,168.6,75.98,168.6,169.1S93.11,337.69,0,337.69" /></svg>',
  }, {
    name: 'record player',
    code: '<svg viewBox="0 0 189.65 232.72" ><path d="M76.13,.49s113.58,24.26,113,49.22c0,0,4.16,20.35-85.27,18.44,0,0-16.64,28.99-44.37,28.99H11.66S.56,93.41,.56,119.93v42.86s-2.34,13.52,21.32,37.17c0,0,15.34,9.1,24.18,17.68v3.38s13.52,1.04,19.76,11.44" /></svg>',
  }, {
    name: 'UFO',
    code: '<svg viewBox = "0 0 236.39 227.75">  <path d="M.01,.5S57.78-1.23,56.53,76.55c0,0,36.98,3.86,77.13,29.49,0,0,5.67,0,7.49,4.76,0,0,81.89,8.39,93.69,19.28,0,0,4.08,4.54-3.86,8.62,0,0,15.65,8.62-52.17,16.79,0,0-18.83,1.81-25.18,2.27,0,0-13.39,15.17-67.6,15.63,0,0-.87,17.81-37.79,39.09C26.14,225.22,12.12,227.25,.01,227.25"/></svg>'
  }]

  function createMarkup() {
    return { __html: 'First &middot; Second' };
  }


  // settings
  const [offset, setOffset] = useState(0)
  const [gores, setGores] = useState(8)
  const [factor, setFactor] = useState(1)
  const [showWireframe, setShowWireframe] = useState(true)


  const [spacedPoints, setSpacedPoints] = useState([])
  const [splineSVG, setSplineSVG] = useState()
  const [pointSegments, setPointsSegments] = useState(200)
  const [curve, setCurve] = useState()
  const [spacedLength, setSpacedLength] = useState()
  const [lengthsSideC, setLengthsSideC] = useState([])
  const [lengths, setLengths] = useState([])
  const [arcLength, setArcLength] = useState()
  const [goreWidth, setGoreWidth] = useState(0)
  const [goreHeight, setGoreHeight] = useState(0)

  const [svgPaths, setSvgPaths] = useState([])
  const [svgCode, setSvgCode] = useState(shapes.find(x => x.name === 'UFO').code)
  const canvasObj = useRef(null)
  const goreCanvas = useRef(null)
  const svgCanvas = useRef(null)
  const svg = useRef(null)
  const [settings, setSettings] = useState({ color: '0x111111' })
  const [showColor, setShowColor] = useState(false)
  const [path, setPath] = useState('')



  const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  const camera = new THREE.PerspectiveCamera(45, (window.innerWidth - 645) / window.innerHeight, .1, 90000);

  const getLengthc = (a, b, C) => {
    const cRad = C / 180 * Math.PI
    if (cRad > 0.001)
      return Math.sqrt(a * a + b * b - 2 * a * b * Math.cos(cRad));
    else
      return Math.sqrt((a - b) * (a - b) + a * b * cRad * cRad * (1 - cRad * cRad / 12));
  }

  useEffect(() => {
    const loader = new SVGLoader();
    const svgData = loader.parse(svgCode);
    setSplineSVG(svgCode)
    const paths = svgData.paths.map((path, i) => {
      return path.currentPath
    })
    setSvgPaths(paths)
  }, [svg, svgCode])

  useEffect(() => {

    if (svgPaths.length) {

      renderer.setSize(window.innerWidth - 645, window.innerHeight)
      renderer.setClearColor(0x000000, 0); // the default
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.update();

      const curve = svgPaths[0]
      setCurve(curve)
      const points = curve.getSpacedPoints(pointSegments);

      const pointsForRenderer = curve.getSpacedPoints(50);
      const pointsForRendererOffset = pointsForRenderer.map((point) => {
        return new THREE.Vector2(Number(point.x) + Number(offset), point.y)
      })

      curve.arcLengthDivisions = 500;
      const scene = new THREE.Scene();

      canvasObj.current.appendChild(renderer.domElement);
      camera.position.x = -1005;
      camera.position.z = -1005;

      camera.zoom = 2.5;
      camera.updateProjectionMatrix();

      const geometry = new THREE.LatheGeometry(pointsForRendererOffset, gores, 0, 6.283185307179586);
      const group = new THREE.Group();
      const meshMaterial = new THREE.LineBasicMaterial({ color: 0x00FF00, wireframe: true, flatShading: true, opacity: .6, transparent: true })
      const meshMaterial2 = new THREE.MeshPhongMaterial({ color: eval(settings.color), emissive: 0x000000, side: THREE.DoubleSide, flatShading: true });
      showWireframe && group.add(new THREE.LineSegments(geometry, meshMaterial));
      group.add(new THREE.Mesh(geometry, meshMaterial2));
      group.scale.y *= -1;
      scene.add(group);

      const color = 0xFFFFFF;
      const intensity = 1;
      const light = new THREE.AmbientLight(color, intensity);
      scene.add(light);

      const light2 = new THREE.DirectionalLight(color, intensity);
      light2.position.set(0, 10, 0);
      light2.target.position.set(50, 0, 0);
      scene.add(light2);
      scene.add(light2.target);

      group.position.y = 100

      animate();
      function animate() {
        requestAnimationFrame(animate);
        controls.update();
        group.rotation.y -= 0.005;
        renderer.render(scene, camera);
      }

      setSpacedPoints(points)
      setArcLength(curve.getLength())
      return function cleanup() {
        THREE.Cache.clear()
        renderer.renderLists.dispose()
        renderer.domElement.remove()
      };
    }
  }, [svgPaths.length, svg.current, svgCode, pointSegments, gores, offset, showWireframe, settings.color])

  useEffect(() => {
    const lengthsArray = spacedPoints.map((point) => {
      return point.x
    })
    setLengths(lengthsArray.reverse())
  }, [spacedPoints, gores, factor])

  useEffect(() => {
    if (spacedPoints.length > 0) {
      setSpacedLength(spacedPoints[0].distanceTo(spacedPoints[1]))
    }
  }, [spacedPoints])

  useEffect(() => {
    const lengthsC = lengths.map((point) => {
      return getLengthc(point, point, 360 / gores)
    })
    setLengthsSideC(lengthsC)
  }, [lengths])

  useEffect(() => {
    const c = goreCanvas.current;
    const ctx = c.getContext("2d");
    setGoreWidth(arcLength * factor + 20)

    const canvasHeightMax = Math.ceil(Math.max(...lengthsSideC))
    setGoreHeight(Number(canvasHeightMax) + offset * 2 + 10)


    goreCanvas.current.height = Number(canvasHeightMax) + offset * 2 + 10
    //svgCanvas.current.setAttribute('height', Number(canvasHeightMax) + offset * 2 + 10)

    goreCanvas.current.width = Math.ceil(Math.max(arcLength + 20))
    //svgCanvas.current.setAttribute('width', Math.ceil(Math.max(arcLength + 20)))


    const cool = [];
    const cool2 = []

    lengthsSideC.map((length, index) => {
      const x1 = index * spacedLength + 10
      const y1 = (goreCanvas.current.height / 2) - length / 2 - Number(offset)
      const x2 = index * spacedLength + 10
      const y2 = (length / 2 * factor) + (goreCanvas.current.height / 2) + Number(offset)

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.strokeStyle = "#00FF00"
      ctx.stroke();

      ctx.beginPath();
      ctx.arc(index * spacedLength + 10 * factor, goreCanvas.current.height / 2 + length / 2 * factor + Number(offset), .5, 0, 2 * Math.PI);
      ctx.strokeStyle = "#FF0000"
      ctx.stroke();


      cool.push([index * spacedLength + 10 * factor, goreCanvas.current.height / 2 + length / 2 * factor + Number(offset)])

      ctx.beginPath();
      ctx.arc(index * spacedLength + 10 * factor, goreCanvas.current.height / 2 + ((length / 2) * -1 - Number(offset)) * factor, .5, 0, 2 * Math.PI);
      ctx.strokeStyle = "#0000FF"
      ctx.stroke();


      cool2.push([index * spacedLength + 10 * factor, goreCanvas.current.height / 2 + ((length / 2) * -1 - Number(offset)) * factor])


    })

    const cool3 = cool.concat(cool2.reverse())


    setPath(svgPath(cool3, bezierCommand))





  }, [lengthsSideC, factor, offset])


  const handleChange = (e) => {
    const { value } = e.target;
    setGores(value)
  };
  const handleOffsetChange = (e) => {
    const { value } = e.target;
    setOffset(value)
  };
  const handleSvgChange = (e) => {
    const { value } = e.target;
    setSvgCode(value)
  };
  const handlePointChange = (e) => {
    const { value } = e.target;
    setPointsSegments(value)
  };

  const handleCanvasResize = (e) => {
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
    renderer.setSize(window.innerWidth, window.innerHeight)
  }

  const handleWireframeToggle = (e) => {
    setShowWireframe(e.target.checked)
  }

  const handleSelectChange = (e) => {
    setSvgCode(shapes[e.target.value])
  }

  const handleChangeComplete = (color) => {
    console.log(color)
    console.log(settings)
    setSettings({ color: `0x${color.hex.substring(1)}`, colorHex: color.hex });
  }

  const handleColorClick = (e) => {
    setShowColor(!showColor)
  }



  return (
    <>
      <div className="grid">
        <div className="settings">
          <div>
            <h1>agoremania</h1>
            <p>A gore is a sector of a curved surface that can be flattened to a plane surface without distortion.</p>
            <p>This app allows you to generate gores from arbitrary SVG paths that can be printed and assembled into three-dimensional objects.</p>
          </div>
          <div>
            <hr />
            <div className="svgPicker">
              <label>SVG</label>
              {/* <select onChange={handleSelectChange}>
                <option value="sphere">Sphere</option>
                <option value="UFO">UFO</option>
                <option value="recordPlayer">recordPlayer</option>
                <option value="custom">Custom</option>
              </select> */}
            </div>
            <textarea value={svgCode} onChange={handleSvgChange} />
            <hr />
            <label>Preview</label>
            <div
              ref={svg}
              className="svgContainer"
              dangerouslySetInnerHTML={{ __html: svgCode }}
            />
          </div>
        </div>
        <div className="shapeContainer">
          <div className="innerGore">
            <div className="shape" ref={canvasObj} onClick={handleCanvasResize}></div>

          </div>
        </div>
        <div className="settings">
          <div>
            <label>Gore Settings</label>
            <div className="dataBox">
              <div className="flex">
                <label>Gores </label>
                <input type="number" min="2" max="100" value={gores} onChange={handleChange} />
              </div>
              <div className="flex">
                <label>Subdivisions </label>
                <input type="number" min="10" max="5000" value={pointSegments} onChange={handlePointChange} />
              </div>
              <div className="flex">
                <label>Offset </label>
                <input type="number" min="0" max="500" value={offset} onChange={handleOffsetChange} />
              </div>
            </div>
            <hr />
            <label>Object Settings</label>
            <div className="dataBox">
              <div className="flex">
                <label>Wireframe</label>
                <input type="checkbox" checked={showWireframe} onChange={handleWireframeToggle} />
              </div>
              <div className="flex">
                <label>Material Color</label>
                <div className="colorClicker" style={{ backgroundColor: settings.colorHex }} onClick={handleColorClick} />
              </div>
              {showColor && <SketchPicker
                color={settings.colorHex}
                onChangeComplete={handleChangeComplete}
              />}
            </div>
            <div>
              <hr />
              <div className="flex">
                <label>Arc Length</label>
                <div>{arcLength}</div>
              </div>
              <div className="flex">
                <label>Segment Length</label>
                <div>{spacedLength}</div>
              </div>
            </div>
          </div>

          <div>
            <hr />
            <label>Export</label>
            <div className="genHolder">
              <div className="lil">PNG</div>
              <canvas className="gore" width={goreWidth ? goreWidth : 500} height="200" ref={goreCanvas}></canvas>
            </div>
            <div className="genHolder">
              <div className="lil">SVG</div>
              <svg
                className="svgPath"
                ref={svgCanvas}
                viewBox={`0 0 ${Math.ceil(goreWidth)} ${Math.ceil(goreHeight)}`}
                dangerouslySetInnerHTML={{ __html: path }}
              ></svg>
            </div>


            <div className="goreInfo">generated gore - print {gores}x</div>
            <button>Export PNG</button>
            <button>Export SVG</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
